import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import './DiscoverIndustryCard.scss'

const DiscoverIndustryCard = (props) => {
    //console.log("discover",props);
    
  return (
    <section className="discover-more">
      <Container>
        <h2 className="section-title">{props.Title}</h2>
        <Row>
          {props?.property_card?.map((item, index) => (
            <Col md={4} key={index} className="discover-card">
              <div className="image-wrapper">
                <img src={item.image?.url} alt={item.image?.alternativeText} />
              </div>
              <h3 className="card-title">{item.title}</h3>
              <p className="card-description">{item.sub_title}</p>
              {/* <a href='javascript:void()' className='cta-button'>{item.button_text}</a> */}
               <a href="javascript:;" className="cta-button" onClick={(e) => props.openModalForm(e,item?.button_text,item?.link )}><span>{item.button_text}</span></a> 
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default DiscoverIndustryCard