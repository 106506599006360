import React from 'react'
import './InnerBanner.scss'

const InnerBanner = (props) => {

  return (
    <section
      className="banner-section"
      style={{ backgroundImage: `url(${props?.InnerBannerImg.url})` }}
    >
      <span className='shaded-bg'></span>
      <div className="banner-overlay">
        <h2 className="banner-title">{props?.InnerBannerTitle}</h2>
        {/* <a href='javascript:void();' className="banner-button">
          {props?.CTA_1_Label}
        </a> */}
        <div className='cta-label-section'>
                  {props?.CTA_1_Label &&
                  <a href="javascript:;" className="banner-button" onClick={(e) => props.openModalForm(e,props?.CTA_1_Label,"multifamily_enquiry_form" )}><span>{props?.CTA_1_Label}</span></a> }

                  {props?.CTA_2_Label &&
                  <a href='#contact-form' className="banner-button">
                    {props?.CTA_2_Label}
                  </a>}

                {props?.CTA_3_Label &&
                 <a href="javascript:;" className="banner-button" onClick={(e) => props.openModalForm(e,props?.CTA_3_Label,"multifamily_enquiry_form_new" )}><span>{props?.CTA_3_Label}</span></a> }
                </div>
      </div>
    </section>
  )
}

export default InnerBanner