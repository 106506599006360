import React, { useState, useEffect } from "react"
import * as Icon from 'react-bootstrap-icons';
import PlayVideo from "../components/Play/PlayVideo";
import {youtube_parser} from "../components/common/utils";

const HelpModule = (props) => {

    const [isPlay,setPlay] = useState(false);

    var video_id = props.Embed_Video_Link && youtube_parser(props.Embed_Video_Link);

    //console.log("video_link", video_id);

    return (
        <>
            <div className="help_cnt"><Icon.InfoCircleFill onClick={() => setPlay(true)}  /></div>

            <PlayVideo
              isOpen = { isPlay } 
              isCloseFunction = { setPlay }
              videoId={video_id}
              isAutoPlay={1}
            />
        </>
    )
}

export default HelpModule