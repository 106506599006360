import React, { useState, useEffect } from "react"
import { Form } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import './assets/styles/_index.scss'
import { postFormData, postStbFormData } from "./api/Api"
import { phoneNumberValidation, uniqueID, Mail_Function, raptorFormSchema } from "../common/utils"
import { PropertyBaseAPI } from "../../queries/api_links"

import axios from "axios"
import * as qs from "query-string"
import { navigate } from "@reach/router"

import {PreQualificationQst} from "../../queries/common_use_query"

function SnaggingForm(props) {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [ischeck, setIscheck] = useState("No");
  const [communicationChannels, setCommunicationChannels] = useState("WhatsApp");

  const[prequal_qst, setPrequal_Qst] = React.useState();

  const {loading, error, data} = PreQualificationQst();

  React.useEffect(()=>{
    data && setPrequal_Qst(data?.preQualifiedQuestionsAnswer?.data?.attributes);
  },[data])

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  var property_type = [];
  prequal_qst && prequal_qst.Property_Type.length > 0 && prequal_qst.Property_Type.map(item => property_type.push(item.Property_Type)); 

  var form_type_url = props.form_type.replace(/ /g, '-');
  const fields = ([
      {
        element: "config",
        formname: props.form_name,
        formname_admin: props.form_name === "Snagging & Handover - Request a Snagging Report" ? "Snagging Report" : props.form_name === "Snagging & Handover - Book an Inspection" ? "Snagging - Book an Inspection" : props.form_name,
        form_type: props.form_type.replace(/ /g, '-'),
        error_text: "Highlighted fields are required | invalid",
        success_text: "Thank you for contacting us. A member of our team will contact you shortly.",
        email_temp_user: "snagging_user",
        email_temp_admin: "snagging_admin",
        email_server_func: "snagging-form",
        event_tracking: props.form_type.replace(/ /g, '-'),
        page_url: "/services/other-services/snagging-and-handover-services",

        email_subject_user: form_type_url === "book-an-inspection" ? "Book an Inspection" : form_type_url === "book-an-appointment" ? "Book an Appointment" : "Request a Snagging Report",

        email_subject_admin: form_type_url === "book-an-inspection" ? "Snagging - Book an Inspection" : form_type_url === "book-an-appointment" ? "Book an Appointment" : "Snagging Report"
      },
      {
        grpmd: "12",
        label: "Property Type",
        placeholder: "Select",
        name: "property_type",
        element: "select",
        required: true,
        values: property_type,
        labelClass: "annual-txt",
        class: "full_wdth_box popup_select"
      },
      {
        grpmd: "12",
        label: "First Name",
        placeholder: "",
        name: "first_name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9]+(\\s+[a-zA-Z0-9]+)*$",
        labelClass: "annual-txt"
      },
      {
        grpmd: "12",
        label: "Last Name",
        placeholder: "",
        name: "last_name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9]+(\\s+[a-zA-Z0-9]+)*$",
        labelClass: "annual-txt"
      },
      {
        grpmd: "12",
        label: "Phone Number",
        placeholder: "+971 12345678",
        name: "telephone",
        type: "text",
        element: "input",
        required: true,
        patternchk: ".{5,20}",
        labelClass: "annual-txt"

      },
      {
        grpmd: "12",
        label: "Email Address",
        placeholder: "",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
        labelClass: "annual-txt"

      },
      {
        grpmd: "12",
        label: "Message",
        placeholder: "",
        name: "message",
        element: "textarea",
        class: "mb-4",
        required: false,
        rows:"3",
        labelClass: "annual-txt"
      },
      {
        grpmd: "12",
        label: "Get emails with the latest news and information on the local property market.",
        name: "newsletter_subscribe",
        element: "checkbox",
        class: "col-md-12 data form-check form-check-inline",
        value: "",
        type: "newsletter_subscribe"
      },
      {
        grpmd: "6",
        name: "SUBMIT",
        type:"submit",
        element: "button",
        value: "Submit",
        class: "btn-primary col-md-12",
        labelClass: "",
        customClass:"btn-wrapper col-md-12 col-sm-12 col-xs-12"
      },
      
      {
        text: 'By clicking Submit, I agree to The Pearl Gates <a href="/terms-and-conditions" className="content-link">Terms & Conditions</a>',
        element: "html",
        class: "mb-md-0 mb-3 mt-md-5 mt-3 content_r-m text-center"
      },
      {
        element: "captcha",
        class: "py-2 ",
        customClass:"col-md-12",
        captchaRef: recaptchaRef
      },
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    //console.log("select_event",event);
    if(event.type === "select"){
      event.value = event.value.trimStart()
    } else{
      if(event.target.name === "telephone"){
        var valid_phone = phoneNumberValidation(event.target.value);
        if(valid_phone){
          event.target.value = valid_phone;
        } else{
          event.target.value = '';
        }
      } else{
        event.target.value = event.target.value.trimStart()
      }
    }

  }

  useEffect(() => {

    //console.log("token", token);
    
    if (token !== '') {

 const processFromData = async () => {

      let formData = new FormData();
      formvalues['newsletter_subscribe'] = ischeck
      formvalues['communicationChannels'] = communicationChannels

      formData.append('data', JSON.stringify(formvalues));

      postFormData(formData).then(async apiRes => {

        formvalues['g_recaptcha_response'] = token;
        await Mail_Function(formvalues);
        window.grecaptcha.reset();
 
      });
      postStbFormData(raptorFormSchema(formvalues)).then(async apiRes => {  
        console.log('raptor submission', apiRes)
       });

      /* ======== Property-base API ======== */
      var query_params = "contact[FirstName]="+formvalues.first_name+"&contact[LastName]="+formvalues.last_name+"&contact[Email_2__c]="+formvalues.email+"&contact[MobilePhone]="+formvalues.telephone+"&contact[LeadSource]=website&contact[pba__Comment_pb__c]="+formvalues.message+"&contact[pba__ContactType_pb__c]="+formvalues.form_name.replace('&', 'and')+"&contact[RecordTypeId]=0124v000000AZUGAA4&contact[New_Website__c]=true&contact[Subscribed_For_Monthly_Newsletter__c]=true&contact[pba__LeadRoutingCompleted__c]=false";

      //console.log("query_params", query_params);
      
      PropertyBaseAPI(query_params).then(response => {
        //console.log("property_base res", response)
        if(response.status === 200){
          console.log('property_base', 'success')
        } else{
          console.log('property_base', 'status error')
        }        
      }).catch(err =>
        console.log("property_base", "error")
      );
      /* ======== Property-base API ======== */

      // const axiosOptions_netlify = {
      //   url: fields[0].page_url,
      //   method: "post",
      //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
      //   data: qs.stringify(formvalues),
      // }

      // axios(axiosOptions_netlify)
      //   .then(response => {
      //     console.log('success 2', 'data stored')
      //   })
      //   .catch(err =>
      //     console.log("err 2", err)
      //   );
      // const url = typeof window !== 'undefined' ? window.location.href : ''  
      // if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
      //   fields[0].formname = 'Get mortgage help';
      // }

      // tracking event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmit',
        'formType': 'form-'+fields[0].event_tracking,
        'formId': 'form-'+fields[0].event_tracking,
        'formName': fields[0].formname,
        'formLabel': props.formLabel
      });

      setShowerror(false);
      setThankyou(true);
      setIscheck("No");
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['name'] = json.first_name+' '+json.last_name;
      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g_recaptcha_response'] = token;

      json['extra'] = {"property_type": json.property_type};

      json['email_subject_user'] = fields[0].email_subject_user;
      json['email_subject_admin'] = fields[0].email_subject_admin;

      //console.log("formsdata_json", json);

      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);
        

      // reset form
      const form = event.target
      form.reset();
      
    }
  };

  //console.log("form_values", props.form_values)

  return (
    <div className={`form stbform`}>
    <div ref={myRef} />

    {showerror && <div className="alert alert-danger ">
      {fields[0].error_text}
    </div>}

    {showthankyou && <div className="alert alert-success">
      {fields[0].success_text}
    </div>}

    
      <Form name={fields[0].formname} className="contact-form" action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit}>

        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_name_admin" value={fields[0].formname_admin} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="to_email_id" value={"pearlgates-allemail@starberry.tv"} />
        <input type="hidden" name="bot-field" />

        <Form.Row>
        {fields.map((field, index) => {
            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  phoneNumber={field.name === 'telephone' ? phoneNumber : ''}
                  setPhoneNumber={field.name === 'telephone' ? setPhoneNumber : ''}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                name={field.name}
                grpmd={field.grpmd}
                label={field.label}
                ref={field.ref}
                value={field.value}
                fieldClass={field.class}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.name}~${index}`}
                handlechange={handlechange}
                setIscheck={setIscheck}
                ischeck={ischeck}
                setCommunicationChannels={setCommunicationChannels}
                communicationChannels={communicationChannels}
                type={field.type}
              />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                  customClass={field.customClass}
                />
              );
            }
          

            if ("button" === field.element) {
              return (
                <>
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                  grpmd={field.grpmd}
                  customClass={field.customClass}
                />
                
                </>
              );
            }
          })
        }
        </Form.Row>
      </Form>
    </div>
  );
}


const SnaggingFormPage = (props) => (
  <SnaggingForm {...props} />
)

export default SnaggingFormPage