import { Link } from "@StarberryUtils";
import React, { useState, useEffect, createRef } from "react"
import { Button, Container, Row, Col } from 'react-bootstrap';
import PlayVideo from "../../Play/PlayVideo"
import _ from "lodash";
import { ImageModule, SourceBlock } from '../../../modules/Image_Module';
import "./Grid-block.scss"
import $ from "jquery"
import HTMLReactParser from 'html-react-parser';
import { useLocation } from "@reach/router";
import bgCurve from "../../../images/home/pg-logo-curve.webp"

const GridBlock = (props) => {

  let location = useLocation();
  let bgDesign = '';
  let customClass = '';
  if ( location.pathname.includes('/property-management-packages') || location.pathname.includes('/interior-design-in-qatar') ) {
    bgDesign = 'true';
  }
 if (location.pathname.includes("development-management-and-advisory")) {
  customClass = "dev_mgmt_and_advisory";
 }
 let nameCls = ""
 if(props.pageAlias === "land-and-developments"){
  nameCls = "col-12 col-sm-6 col-lg-3 col-md-4"
 }else{
    nameCls = "col-12 col-sm-6 col-lg-4 col-md-4"
 }

  return (
    <React.Fragment>
      <div className={`grid-block ${customClass}`}>
        {bgDesign == 'true' &&
          <span className="bg-curve"><img src={bgCurve}  alt="The Pearl Gates" /></span>
        }
        <Container>
        <Row>
          {props.Grid_Title != null &&
            <div className="grid-title"><h2>{props.Grid_Title}</h2></div>
          }
          {props.Grid_Content != null &&
            <p className="grid-content">{HTMLReactParser(props.Grid_Content)}</p>
          }
          {props.Grid_List.map((item,i) => {
            return (

              <Col className={nameCls} key={i}>
                <div className="list">
                  <img src={item.Image.url} />
                  <h4>{item.Title}</h4>
                  <p className="desc">{item.Content && HTMLReactParser(item.Content)} </p>
                  {item.CTA_Link?.Primary_URL != null &&
                    <Link to={item.CTA_Link?.Primary_URL ? item.CTA_Link?.Primary_URL : "#"} className=""><span>{item.CTA_Label}</span></Link>
                  }
                </div>
              </Col>

            )
          })}
        </Row>
        <div className="btn-bottom text-center d-none">
          {location.pathname.includes('/interior-design-in-qatar') ? 
          <Link href="/our-offices/real-estate-agents-in-qatar/?action=Interior%20Designs" className="btn btn-primary"><span>Enquire Now</span></Link> :
          <Link href="/our-offices/real-estate-agents-in-qatar/" className="btn btn-primary"><span>Enquire Now</span></Link>}
        </div>
        </Container>
      </div>


    </React.Fragment>
  )
}


export default GridBlock