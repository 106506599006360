import React, { useState, useEffect} from "react"
import {Container, Row, Col, Badge, Nav,Card} from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import "./PropertyFacts.scss"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HTMLReactParser from 'html-react-parser';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import $ from 'jquery';
import Slider from "react-slick";
// styles

// markup
const ProjectFacts = (props) => {

  const propertyfacts = props?.data;
  const pageData = props?.pageData;
  let isNewLayout = false;

  const newLayoutPages = ["l-horizon-by-elie-saab", "gated-compound-the-pearl-doha", "giardino-reserve-giardino-village-the-pearl-qatar"];

  isNewLayout = newLayoutPages.includes(pageData.Alias);

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const [currentType, setCurrentType] = useState("");

  const openLighboxMobile = (e, ind, type) => {
    setCurrentType(type);
    e.preventDefault();
    setPhotoIndex(ind);
    setIsOpen(true);
  }   

  var lightImages2 = currentType === 'slider'? propertyfacts?.property_card &&  propertyfacts?.property_card?.map(image => image?.image?.url) :
  propertyfacts.floor_plans && propertyfacts.floor_plans.map(img => img.url);  

  // Slider settings
  let settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 800,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                dots: true,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                dots: true,
            },
        },
        //{
            //breakpoint: 1300,
            //settings: "unslick",
        //},
    ],
  }

  return (
    <React.Fragment>
      <>
        {propertyfacts?.Payment_Plan_Modules?.length > 0 && isNewLayout &&
          <div className="property-facts-section properties-facts-section" id="our_payment_plan_new">
            <div className="">
              <div className="property-facts-title">
                {propertyfacts?.Payment_Plan_Modules?.length > 0 && <h2>Our Payment Plan</h2>}
                <div className="payment_plan">
                  {propertyfacts?.Payment_Plan_Modules?.length > 0 && propertyfacts?.Payment_Plan_Modules.map((item, index) => {
                    return (
                      <div className="property-toolkit-item">
                        <div className="property-toolkit-info">
                          <h2>{item?.Schedule_of_payments}</h2>
                          <h3>{item?.Payment_Installments}</h3>

                        </div>
                      </div>
                    )
                  })
                  }
                </div>
              </div>
            </div>
          </div>
        }
        {propertyfacts?.floor_plans && propertyfacts?.floor_plans?.length > 0 && isNewLayout &&
          <div className="property-facts-section properties-facts-section" id="floorplans_new">
            <div className="">
              <div className="property-facts-title">
                <h2>Floorplans</h2>
                <div className="property-area-map">
                  {propertyfacts?.floor_plans && propertyfacts?.floor_plans.map((item, index) =>
                    <div className="property-area-map-list" key={index}>
                      <div className="property-area-img" onClick={(e) => openLighboxMobile(e, index, "static")}>
                        <img src={item.url} alt="" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        }
        {
          propertyfacts?.__typename === "GLSTRAPI_ComponentModCollectionBlock" && propertyfacts?.property_card && isNewLayout &&
          <Container>
            <div className='img-wrapper-section img-wrapper-sections property-facts-section properties-facts-section' id="floorplans_new">
              <div className="gallery-slider justify-content-center property-facts-title row">
                <h2 className="section-title">Floorplans</h2>

                <Slider className="gallery-slider" {...settings}>
                  {propertyfacts?.property_card?.length > 0 && propertyfacts.property_card?.map((module, index) => {
                    return (
                      <div className={`img-wrapper-section ${module.image.url}`} key={index}>
                        {module &&
                          <>
                            <picture>
                              <img src={module.image.url} alt={module.image.url} className="banner-img" onClick={(e) => openLighboxMobile(e, index, "slider")} />
                            </picture>
                            <h4 className='card-title'>{module?.title}</h4>
                            {module.sub_title && module.sub_title !== "#" &&
                              <p className='card-price'>{module?.sub_title}</p>
                            }
                          </>
                        }
                      </div>
                    )
                  }
                  )}
                </Slider>

              </div>
            </div>
          </Container>
        }

        <div className="property-facts-section properties-facts-section" id="project_facts">
          <div className="animated">
            <div className="property-facts-title">
              {propertyfacts?.facts_title && isNewLayout && <h2>Project Facts</h2>}
              {propertyfacts?.facts_title &&
                <h3>{propertyfacts.facts_title}</h3>
              }
              {propertyfacts?.facts_content &&
                <div className="description">{HTMLReactParser(propertyfacts.facts_content)}</div>
              }
            </div>
            <div className="project-fact-list">
              <ul>
                {propertyfacts?.location &&
                  <li>
                    <strong>Location</strong>
                    {propertyfacts?.location}
                  </li>
                }
                {propertyfacts?.Ownership &&
                  <li>
                    <strong>Ownership</strong>
                    {propertyfacts?.Ownership}
                  </li>
                }
                {propertyfacts?.Price_Starting_From &&
                  <li>
                    <strong>Price (starting from):</strong>
                    {propertyfacts?.Price_Starting_From}
                  </li>
                }
              </ul>

            </div>
          </div>
        </div>
        {propertyfacts?.Key_Features &&
          <div className="property-facts-section properties-facts-section" id="details_key_features">
            <div className="">
              <div className="property-facts-title">
                <h2>Details & Key Features</h2>
                {propertyfacts?.Key_Features &&
                  <div className="description">{HTMLReactParser(propertyfacts.Key_Features)}</div>
                }
              </div>
            </div>
          </div>
        }
        <div className="property-facts-section properties-facts-section" id="download_form"></div>
        
        {propertyfacts?.Development_Strategy &&
          <div className="property-facts-section properties-facts-section" id="development_strategy">
            <div className="">
              <div className="property-facts-title">
                <h2>Development & Strategy</h2>
                {propertyfacts?.Development_Strategy &&
                  <div className="description">{HTMLReactParser(propertyfacts.Development_Strategy)}</div>
                }
              </div>
            </div>
          </div>
        }
        {propertyfacts?.Payment_Plan_Modules?.length > 0 && !isNewLayout &&
          <div className="property-facts-section properties-facts-section" id="our_payment_plan">
            <div className="">
              <div className="property-facts-title">
                {propertyfacts?.Payment_Plan_Modules?.length > 0 && <h2>Our Payment Plan</h2>}
                <div className="payment_plan">
                  {propertyfacts?.Payment_Plan_Modules?.length > 0 && propertyfacts?.Payment_Plan_Modules.map((item, index) => {
                    return (
                      <div className="property-toolkit-item">
                        <div className="property-toolkit-info">
                          <h2>{item?.Schedule_of_payments}</h2>
                          <h3>{item?.Payment_Installments}</h3>
                        </div>
                      </div>
                    )
                  })
                  }
                </div>
              </div>
            </div>
          </div>
        }
        {propertyfacts?.floor_plans && !isNewLayout &&
          <div className="property-facts-section properties-facts-section" id="floorplans">
            <div className="">
              <div className="property-facts-title">
                <h2>Floorplans</h2>
                <div className="property-area-map">
                  {propertyfacts?.floor_plans && propertyfacts?.floor_plans.map((item, index) =>
                    <div className="property-area-map-list" key={index}>
                      <div className="property-area-img" onClick={(e) => openLighboxMobile(e, index, "static")}>
                        <img src={item.url} alt="" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        }
        {
          propertyfacts?.__typename === "GLSTRAPI_ComponentModCollectionBlock" && propertyfacts?.property_card && !isNewLayout &&

          <Container>
            <div className='img-wrapper-section img-wrapper-sections property-facts-section properties-facts-section' id="floorplans">
              <div className="gallery-slider justify-content-center property-facts-title row">
                <h2 className="section-title">Floorplans</h2>

                <Slider className="gallery-slider" {...settings}>
                  {propertyfacts?.property_card?.length > 0 && propertyfacts.property_card?.map((module, index) => {
                    return (
                      <div className={`img-wrapper-section ${module.image.url}`} key={index}>
                        {module &&
                          <>
                            <picture>
                              <img src={module.image.url} alt={module.image.url} className="banner-img" onClick={(e) => openLighboxMobile(e, index, "slider")} />
                            </picture>
                            <h4 className='card-title'>{module?.title}</h4>
                            {module.sub_title && module.sub_title !== "#" &&
                              <p className='card-price'>{module?.sub_title}</p>
                            }
                          </>
                        }
                      </div>
                    )
                  }
                  )}
                </Slider>

              </div>
            </div>
          </Container>
        }
      </>

   {isOpen && (
          <Lightbox
            mainSrc={lightImages2[photoIndex]}
            nextSrc={lightImages2[(photoIndex + 1) % lightImages2.length]}
            prevSrc={lightImages2[(photoIndex + lightImages2.length - 1) % lightImages2.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + lightImages2.length - 1) % lightImages2.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % lightImages2.length)
            }
          />
        )}

  </React.Fragment>
  )
}

export default ProjectFacts