import React from "react";
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "@StarberryUtils";
import Slider from 'react-slick';
import _ from "lodash"
import "../../components/LatestDevelopments/LatestDevelopments.scss"
import { NewProjects } from "../../queries/common_use_query"
import { removeDataAttributes } from "../../comQueryStructure";
import DevelopmentCard from "./DevelopmentCard";

const LatestDevBlock = (props) => {
  //onsole.log("devv", props)

  const { loading, error, data } = NewProjects(props.pstatus, props.region, 10);
  const featureNewhomeData = removeDataAttributes(data?.newProjects)
  //console.log("dataaa", featureNewhomeData)

  const settings = {
    dots: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: false,
    mobileFirst: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      },
    ]
  }

  return (
    <div className={`bottom-block latest-developments`} id="latest-developments">
      <Container>

        <div className="tab-details">
          <h2 className="tab-title">
            {props?.Bottom_Block?.Bottom_Title}
          </h2>
          <p className="tab-desc">
            {props?.Bottom_Block?.Bottom_Content}
          </p>
        </div>
        {props.Bottom_Block?.Bottom_Title !== "Financing & Legalities in Qatar" &&
        <Row>
          <div className="grid-view-item col-12">
            <div className="development-wrapper">
            
                <Slider {...settings} >
                  {
                    featureNewhomeData?.map((item, index) => {
                      console.log("itemmm", item)
                      var url = "/buy-new-projects-in-qatar";
                      var tag = "";

                      var urlLink = `${url}/${item?.URL}`;
                      return (
                        <DevelopmentCard
                          cardImg={item?.Title_Image}
                          cardTitle={item?.Name}
                          projectType={item?.Project_Type}
                          priceRange={item?.Price_Range}
                          plotSize={item?.Plot_Size}
                          imagetransforms={item?.ggfx_results}
                          ggfxNew={true}
                          cardUrl={urlLink}
                          article_id={props.article_id}
                          location={props.location} 
                        />
                      )
                    })
                  }
                </Slider>
               
            </div>
          </div>
        </Row>
    }
      </Container>
    </div>
  )
}

export default LatestDevBlock
