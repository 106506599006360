import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import loadable from "@loadable/component";
import BrochureFormFields from '../../../static/forms/brochure_form.json'
import ScrollAnimation from 'react-animate-on-scroll';
import './_brochure-module.scss';
const BrochureForms = loadable(() => import("../../components/forms/brochure_form"))
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule");

const BrochureForm = (props) => {
    //console.log("brochhhure",props)
    return (
        <div className={`brochure-module-wrapper overflow-hidden brochure-forms section-menu-block`} id="download_your_brochure">
            <ScrollAnimation delay={160} offset={10} animateIn="animate__slideInUp" animateOnce>
                <Container>
                    <Row className='d-flex align-items-center'>
                        <h2>{props?.modules?.title}</h2>
                        {props?.modules?.contents &&
                            <ContentModule Content={props.modules?.contents} />
                        }
                        <BrochureForms fields={BrochureFormFields} brochureUrl={props?.modules?.brochure_url} classname="enquiry-form-wrapper" subject={`${props.subject} Download Your Brochure`} />
                    </Row>
                </Container>
            </ScrollAnimation>
        </div>
    )
}

export default BrochureForm