import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';
import Slider from 'react-slick';
import './ProcessSlider.scss'

const ProcessSlider = (props) => {
    console.log(props);


    // Slider settings
    let settings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true,
                },
            },
            //{
            //breakpoint: 1300,
            //settings: "unslick",
            //},
        ],
    }

    return (
        <section className="our-process">
            <Container>
                <h2 className="section-title">Our Process</h2>
                <Row>
                    <Slider className="gallery-slider" {...settings}>
                        {props?.Steps.map((step, index) => (
                            <div key={index} className="process-step">
                                <div className="step-number">{index + 1}</div>
                                <div className="process-card">
                                    <h3 className="step-title">{step.Title}</h3>
                                    <p className="step-description">{HTMLReactParser(step.Content)}</p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </Row>
            </Container>
        </section>
    )
}

export default ProcessSlider